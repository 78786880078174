import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const DataPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="spy"/>
          <Header.Content>
            Datenschutzerklärung
            <Header.Subheader>
              Das lästigere Etwas
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <Header as="h3">Datenschutz</Header>
        <p>Nachfolgend möchte ich Dich über meine Datenschutzerklärung informieren. Du findest hier Informationen über die Erhebung und Verwendung persönlicher Daten bei der Nutzung meiner Webseite. Ich beachte dabei das für Europa geltende Datenschutzrecht. Du kannst diese Erklärung jederzeit auf meiner Webseite abrufen.</p>
        <p>Ich weise ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann.</p>
        <p>Die Verwendung der Kontaktdaten meines Impressums zur gewerblichen Werbung ist ausdrücklich nicht gestattet, es sei denn ich hatte zuvor meine schriftliche Einwilligung erteilt oder es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe deiner Daten.</p>

        <Header as="h3">Personenbezogene Daten</Header>
        <p>Du kannst meine Webseite ohne Angabe personenbezogener Daten besuchen. Soweit auf meinen Seiten personenbezogene Daten (wie Name, Anschrift oder E-Mail Adresse) erhoben werden, erfolgt dies, soweit möglich, auf freiwilliger Basis. Diese Daten werden ohne Deine ausdrückliche Zustimmung nicht an Dritte weitergegeben. Sofern zwischen Dir und mir ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder geändert werden soll oder Du an mich eine Anfrage stellst, erhebe und verwende ich personenbezogene Daten von Dir, soweit dies zu diesen Zwecken erforderlich ist (Bestandsdaten). Ich erhebe, verarbeite und nutze personenbezogene Daten soweit dies erforderlich ist, um Dir die Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche personenbezogenen Daten werden nur solange gespeichert wie dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer- und handelsrechtliche Aufbewahrungsfristen berücksichtigt. Auf Anordnung der zuständigen Stellen darf ich im Einzelfall Auskunft über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen Aufgaben der Verfassungsschutzbehörden oder des Militärischen Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist.</p>

        <Header as="h3">Kommentarfunktionen</Header>
        <p>Ich erhebe personenbezogene Daten im Rahmen der Veröffentlichung eines Inhalts oder einer Kommentierung zu einem Artikel oder Beitrag nur in dem Umfang, wie sie von Dir mitgeteilt werden. Bei der Veröffentlichung eines Kommentars wird die von Dir angegebene Email-Adresse gespeichert, aber nicht veröffentlicht. Dein Name wird veröffentlicht, wenn Du nicht unter Pseudonym geschrieben hast.</p>

        <Header as="h3">Datenschutzerklärung für den Webanalysedienst Google Analytics</Header>
        <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (&#8220;Google&#8221;). Google Analytics verwendet sog. &#8220;Cookies&#8221;, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Dich ermöglichen. Die durch den Cookie erzeugten Informationen über Deine Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Ich habe die IP-Anonymisierung aktiviert. Auf dieser Webseite wird Deine IP-Adresse von Google daher innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Deinem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Du kannst die Speicherung der Cookies durch eine entsprechende Einstellung Deiner Browser-Software verhindern; ich weise jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzbar sind. Du kannst darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Deiner IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Du das unter dem folgenden Link verfügbare Browser-Plugin herunterlädst und installierst: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="nofollow">http://tools.google.com/dlpage/gaoptout?hl=de</a></p>

        <Header as="h3">Auskunftsrecht</Header>
        <p>Du hast das jederzeitige Recht, dich unentgeltlich und unverzüglich über die zu Deiner Person erhobenen Daten zu erkundigen. Du hast das jederzeitige Recht, Deine Zustimmung zur Verwendung Deiner angegeben persönlichen Daten mit Wirkung für die Zukunft zu widerrufen. Zur Auskunftserteilung wende Dich bitte an mich unter den Kontaktdaten im Impressum.</p>

        <Header as="h3">Cookies</Header>
        <p>Auf diesem Blog verwende ich teilweise Cookies. Es handelt sich dabei um kleine Textdateien, die auf Deinem Rechner abgelegt werden und die Dein Browser speichert. Sie dienen dazu, mein Angebot benutzerfreundlicher, effektiver und sicherer zu machen.<br />
          Die meisten der von mir verwendeten Cookies sind &#8220;Session-Cookies&#8221;. Diese werden nach Ende Deines Besuchs automatisch wieder gelöscht. Andere Cookies bleiben auf Deinem Endgerät gespeichert, bis Du sie selbst löschst. Diese Cookies ermöglichen es meinem Blog, dein Endgerät beim nächsten Besuch wieder zu erkennen.<br />
          Du kannst Deinen Browser so einstellen, dass Du über das Setzen von Cookies informiert wirst und dann Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen und das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität meines Blogs eingeschränkt sein.</p>

        <Header as="h3">Server-Log-Files</Header>
        <p>Der Provider meines Blogs erhebt und speichert automatisch Informationen in so genannten Server-Log-Files, die Dein Browser/Computer automatisch an uns übermittelt. Dies sind:</p>
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>Diese Daten können nicht bestimmten Personen zugeordnet werden. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Ich behalte mir das Recht vor, diese Daten nachträglich zu prüfen, wenn mir konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>
      </Segment>
    </Container>
  );
};

export default withLayout(DataPage);
